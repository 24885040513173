import React, { useRef, useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import ProfileSection from './components/ProfileSection';
import CareerPath from './components/CareerPath';
import AbilitySection from './components/AbilitySection';
import Footer from './components/Footer';
import Projects from './components/Projects';
import { Analytics } from '@vercel/analytics/react';

import './styles/Styles.css';

const App = () => {
  const homeRef = useRef();
  const profileSectionRef = useRef();
  const careerPathSectionRef = useRef();
  const abilityRef = useRef();
  const projectsSectionRef = useRef();
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Navbar homeRef={homeRef} profileSectionRef={profileSectionRef} projectsSectionRef={projectsSectionRef} careerPathSectionRef={careerPathSectionRef} abilityRef={abilityRef}  />
      <Home homeRef={homeRef} profileSectionRef={profileSectionRef} />
      <ProfileSection profileSectionRef={profileSectionRef} />
      <Projects projectsSectionRef={projectsSectionRef} />
      <CareerPath scrollPos={scrollPos} homeRef={homeRef} profileSectionRef={profileSectionRef} careerPathSectionRef={careerPathSectionRef} projectsSectionRef={projectsSectionRef} />
      <AbilitySection abilityRef={abilityRef} />
      <Footer />
      <Analytics />
    </div>
  );
}

export default App;